<template>
    <b-row class="justify-content-around">
        <performance-indicator-card
            ref="chartIndicatorA"
            :title="i18nUsers"
            range="90-100%"
            tag="A"
            :busy="loading"
            :value="indicatorA"
            :total="totalUsers"
            :text-color="'#64BB77'"
        />
        <performance-indicator-card
            ref="chartIndicatorB"
            :title="i18nUsers"
            range="60-89%"
            tag="B"
            :busy="loading"
            :value="indicatorB"
            :total="totalUsers"
            :text-color="'#32BDF9'"
        />
        <performance-indicator-card
            ref="chartIndicatorC"
            :title="i18nUsers"
            range="40-59%"
            tag="C"
            :busy="loading"
            :value="indicatorC"
            :total="totalUsers"
            :text-color="'#EDB012'"
        />
        <performance-indicator-card
            ref="chartIndicatorD"
            :title="i18nUsers"
            range="1-39%"
            tag="D"
            :busy="loading"
            :value="indicatorD"
            :total="totalUsers"
            :text-color="'#ED6112'"
        />
        <performance-indicator-card
            ref="chartIndicatorE"
            :title="i18nUsers"
            range="0%"
            tag="E"
            :busy="loading"
            :value="indicatorE"
            :total="totalUsers"
            :text-color="'#AF0404'"
        />
    </b-row>
</template>

<script>
import UserPerformanceService from '@src/services/dashboards/UserPerformanceService.js'

import PerformanceIndicatorCard from '@/src/components/dashboards/PerformanceIndicatorCard.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'

const i18nKey = 'DASHBOARDS'
const i18nCommon = 'COMMON'

export default {
    components: {
        PerformanceIndicatorCard,
    },
    mixins: [
        validationMixin,
        swalFeedback,
    ],
    data() {
        return {
            i18nKey,
            i18nCommon,
            additionalParameters: {},
            loading: false,
            indicatorA: 0,
            indicatorB: 0,
            indicatorC: 0,
            indicatorD: 0,
            indicatorE: 0,
            totalUsers: 0,
        }
    },
    validations: {},
    computed: {
        i18nUsers() {
            return this.getI18nModified({
                        prefix: 'USERS',
                        suffix: 'TITLES.user',
                        modifier: 2,
                    })
        },
        i18nPunctualVision() {
            return this.getI18n(i18nKey, 'TITLES.punctual_vision')
        },
        i18nPunctuality() {
            return this.getI18n(i18nCommon, 'punctuality')
        },
        i18nWorkSchedule() {
            return this.getI18n('WORKSCHEDULES', 'TITLES.work_schedule')
        },
        i18nQuestionnaire() {
            return this.getI18n('QUESTIONNAIRES', 'TITLES.questionnaire')
        },
        i18nTimeInStore() {
            return this.getI18n('POS_CHECK', 'FIELDS.time_in_store')
        },
        i18nCheckInDistance() {
            return this.getI18n(i18nCommon, 'checkin_distance')
        },
        i18nCheckOutDistance() {
            return this.getI18n(i18nCommon, 'checkout_distance')
        },
        i18nBattery() {
            return this.getI18n('POS_CHECK', 'FIELDS.battery')
        },
    },
    methods: {
        refresh(parameters) {
            this.loading = true
            this.indicatorA = 0
            this.indicatorB = 0
            this.indicatorC = 0
            this.indicatorD = 0
            this.indicatorE = 0
            this.totalUsers = 0
            this.additionalParameters = parameters

            UserPerformanceService.resume(this.additionalParameters)
                .then((response) => {
                    const data = response.data.data
                    this.indicatorA = data.A
                    this.indicatorB = data.B
                    this.indicatorC = data.C
                    this.indicatorD = data.D
                    this.indicatorE = data.E
                    this.totalUsers = data.total_users

                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    return err
                })
        },
    },
}
</script>
