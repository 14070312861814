import BaseService from '@src/services/BaseService'

class UserPerformanceService extends BaseService {
    constructor() {
        super('user-performance')
    }

    resume(params) {
        return this.axios.post(`${this.getUrl()}/resume`, params)
    }

    statics(params) {
        return this.axios.post(`${this.getUrl()}/statics`, params)
    }
}

export default new UserPerformanceService()

