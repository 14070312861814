<template>
    <form-modal
        ref="formModal"
        :v="$v.adjustScoreModal"
        :title="i18nAdjustScore"
        :in-editing="true"
        size="xl"
        :handle-close="handleCancel">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nAdjustScore"
                    :operation="'edited'"
                />
                <b-row>
                    <b-col md="6">
                        <topic-header
                            :caption="i18nPunctuality"
                            icon="fe-clock"
                        />
                        <b-row>
                            <b-col md="6">
                                <b-form-group
                                    :label="getI18n(i18nKey, 'PERFORMANCE.points_until_the_minimum_time')"
                                    label-for="punctuality-plus-score"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_score')">
                                    <b-input-group class="input-group-pdv-blue">
                                        <template v-slot:prepend>
                                            <b-input-group-text>
                                                <i class="fe-plus"></i>
                                            </b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="punctuality-plus-score"
                                            v-model.number="$v.adjustScoreModal.punctuality_plus_score.$model"
                                            class="input-pdv-blue"
                                            type="number"
                                            min="0"
                                            max="100"
                                            autofocus
                                            autocomplete="off"
                                            :disabled="loading || submitLoading"
                                            :state="validateField('punctuality_plus_score', 'adjustScoreModal')"
                                            @input="clearResponseError('punctuality_plus_score', 'adjustScoreModal')"
                                            @blur="$v.adjustScoreModal.punctuality_plus_score.$touch"
                                        />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group
                                    :label="getI18n(i18nKey, 'PERFORMANCE.points_after_tolerance')"
                                    label-for="punctuality-minus-score"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_score')">
                                    <b-input-group class="input-group-pdv-blue">
                                        <template v-slot:prepend>
                                            <b-input-group-text>
                                                <i class="fe-minus"></i>
                                            </b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="punctuality-minus-score"
                                            v-model.number="$v.adjustScoreModal.punctuality_minus_score.$model"
                                            class="input-pdv-blue"
                                            type="number"
                                            min="0"
                                            max="100"
                                            autocomplete="off"
                                            :disabled="loading || submitLoading"
                                            :state="validateField('punctuality_minus_score', 'adjustScoreModal')"
                                            @input="clearResponseError('punctuality_minus_score', 'adjustScoreModal')"
                                            @blur="$v.adjustScoreModal.punctuality_minus_score.$touch"
                                        />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col md="6">
                        <topic-header
                            :caption="i18nWorkSchedule"
                            icon="fe-list"
                        />
                        <b-row>
                            <b-col md="4">
                                <b-form-group
                                    :label="getI18n(i18nKey, 'PERFORMANCE.points_if_complete')"
                                    label-for="workschedule-plus-score"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_score')">
                                    <b-input-group class="input-group-pdv-blue">
                                        <template v-slot:prepend>
                                            <b-input-group-text>
                                                <i class="fe-plus" />
                                            </b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="workschedule-plus-score"
                                            v-model.number="$v.adjustScoreModal.workschedule_plus_score.$model"
                                            class="input-pdv-blue"
                                            type="number"
                                            min="0"
                                            max="100"
                                            autocomplete="off"
                                            :disabled="loading || submitLoading"
                                            :state="validateField('workschedule_plus_score', 'adjustScoreModal')"
                                            @input="clearResponseError('workschedule_plus_score', 'adjustScoreModal')"
                                            @blur="$v.adjustScoreModal.workschedule_plus_score.$touch"
                                        />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="5">
                                <b-form-group
                                    :label="getI18n(i18nKey, 'PERFORMANCE.points_if_not_do_the_minimum')"
                                    label-for="workschedule-minus-score"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_score')">
                                    <b-input-group class="input-group-pdv-blue">
                                        <template v-slot:prepend>
                                            <b-input-group-text>
                                                <i class="fe-minus" />
                                            </b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="workschedule-minus-score"
                                            v-model.number="$v.adjustScoreModal.workschedule_minus_score.$model"
                                            class="input-pdv-blue"
                                            type="number"
                                            min="0"
                                            max="100"
                                            autocomplete="off"
                                            :disabled="loading || submitLoading"
                                            :state="validateField('workschedule_minus_score', 'adjustScoreModal')"
                                            @input="clearResponseError('workschedule_minus_score', 'adjustScoreModal')"
                                            @blur="$v.adjustScoreModal.workschedule_minus_score.$touch"
                                        />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group
                                    :label="getI18n(i18nCommon, 'minimum')"
                                    label-for="workschedule-mininum"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_score')">
                                    <b-input-group class="input-group-pdv-blue">
                                        <template v-slot:append>
                                            <b-input-group-text>
                                                <i class="fe-percent" />
                                            </b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="workschedule-mininum"
                                            v-model.number="$v.adjustScoreModal.workschedule_mininum.$model"
                                            class="input-pdv-blue"
                                            type="number"
                                            min="0"
                                            max="100"
                                            autocomplete="off"
                                            :disabled="loading || submitLoading"
                                            :state="validateField('workschedule_mininum', 'adjustScoreModal')"
                                            @input="clearResponseError('workschedule_mininum', 'adjustScoreModal')"
                                            @blur="$v.adjustScoreModal.workschedule_mininum.$touch"
                                        />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6">
                        <topic-header
                            :caption="i18nQuestionnaire"
                            icon="fe-file-text"
                        />
                        <b-row>
                            <b-col md="6">
                                <b-form-group
                                    :label="getI18n(i18nKey, 'PERFORMANCE.points_if_complete')"
                                    label-for="questionnaire-plus-score"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_score')">
                                    <b-input-group class="input-group-pdv-blue">
                                        <template v-slot:prepend>
                                            <b-input-group-text>
                                                <i class="fe-plus" />
                                            </b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="questionnaire-plus-score"
                                            v-model.number="$v.adjustScoreModal.questionnaire_plus_score.$model"
                                            class="input-pdv-blue"
                                            type="number"
                                            min="0"
                                            max="100"
                                            autocomplete="off"
                                            :disabled="loading || submitLoading"
                                            :state="validateField('questionnaire_plus_score', 'adjustScoreModal')"
                                            @input="clearResponseError('questionnaire_plus_score', 'adjustScoreModal')"
                                            @blur="$v.adjustScoreModal.questionnaire_plus_score.$touch"
                                        />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col md="6">
                        <topic-header
                            :caption="i18nTimeInStore"
                            icon="fe-shopping-cart"
                        />
                        <b-row>
                            <b-col md="4">
                                <b-form-group
                                    :label="getI18n(i18nKey, 'PERFORMANCE.points_if_it_stays_longer')"
                                    label-for="time-in-store-plus-score"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_score')">
                                    <b-input-group class="input-group-pdv-blue">
                                        <template v-slot:prepend>
                                            <b-input-group-text>
                                                <i class="fe-plus" />
                                            </b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="time-in-store-plus-score"
                                            v-model.number="$v.adjustScoreModal.time_in_store_plus_score.$model"
                                            class="input-pdv-blue"
                                            type="number"
                                            min="0"
                                            max="100"
                                            autocomplete="off"
                                            :disabled="loading || submitLoading"
                                            :state="validateField('time_in_store_plus_score', 'adjustScoreModal')"
                                            @input="clearResponseError('time_in_store_plus_score', 'adjustScoreModal')"
                                            @blur="$v.adjustScoreModal.time_in_store_plus_score.$touch"
                                        />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="getI18n(i18nKey, 'PERFORMANCE.points_if_it_gets_less')"
                                    label-for="time-in-store-minus-score"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_score')">
                                    <b-input-group class="input-group-pdv-blue">
                                        <template v-slot:prepend>
                                            <b-input-group-text>
                                                <i class="fe-minus" />
                                            </b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="time-in-store-minus-score"
                                            v-model.number="$v.adjustScoreModal.time_in_store_minus_score.$model"
                                            class="input-pdv-blue"
                                            type="number"
                                            min="0"
                                            max="100"
                                            autocomplete="off"
                                            :disabled="loading || submitLoading"
                                            :state="validateField('time_in_store_minus_score', 'adjustScoreModal')"
                                            @input="clearResponseError('time_in_store_minus_score', 'adjustScoreModal')"
                                            @blur="$v.adjustScoreModal.time_in_store_minus_score.$touch"
                                        />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="getI18n('POS_CHECK', 'FIELDS.time_in_store')"
                                    label-for="time-in-store-minutes"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_score')">
                                    <b-input-group class="input-group-pdv-blue">
                                        <template v-slot:append>
                                            <b-input-group-text :title="i18nMinutes">
                                                min
                                            </b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="time-in-store-minutes"
                                            v-model.number="$v.adjustScoreModal.time_in_store_minutes.$model"
                                            class="input-pdv-blue"
                                            type="number"
                                            min="0"
                                            max="120"
                                            autocomplete="off"
                                            :disabled="loading || submitLoading"
                                            :state="validateField('time_in_store_minutes', 'adjustScoreModal')"
                                            @input="clearResponseError('time_in_store_minutes', 'adjustScoreModal')"
                                            @blur="$v.adjustScoreModal.time_in_store_minutes.$touch"
                                        />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6">
                        <topic-header
                            :caption="i18nCheckInDistance"
                            icon="fe-log-in"
                        />
                        <b-row>
                            <b-col md="4">
                                <b-form-group
                                    :label="getI18n(i18nKey, 'PERFORMANCE.points_if_within_the_radius')"
                                    label-for="check-in-distance-plus-score"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_score')">
                                    <b-input-group class="input-group-pdv-blue">
                                        <template v-slot:prepend>
                                            <b-input-group-text>
                                                <i class="fe-plus" />
                                            </b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="check-in-distance-plus-score"
                                            v-model.number="$v.adjustScoreModal.check_in_distance_plus_score.$model"
                                            class="input-pdv-blue"
                                            type="number"
                                            min="0"
                                            max="100"
                                            autocomplete="off"
                                            :disabled="loading || submitLoading"
                                            :state="validateField('check_in_distance_plus_score', 'adjustScoreModal')"
                                            @input="clearResponseError('check_in_distance_plus_score', 'adjustScoreModal')"
                                            @blur="$v.adjustScoreModal.check_in_distance_plus_score.$touch"
                                        />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="getI18n(i18nKey, 'PERFORMANCE.points_if_out_of_radius')"
                                    label-for="check-in-distance-minus-score"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_score')">
                                    <b-input-group class="input-group-pdv-blue">
                                        <template v-slot:prepend>
                                            <b-input-group-text>
                                                <i class="fe-minus" />
                                            </b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="check-in-distance-minus-score"
                                            v-model.number="$v.adjustScoreModal.check_in_distance_minus_score.$model"
                                            class="input-pdv-blue"
                                            type="number"
                                            min="0"
                                            max="100"
                                            autocomplete="off"
                                            :disabled="loading || submitLoading"
                                            :state="validateField('check_in_distance_minus_score', 'adjustScoreModal')"
                                            @input="clearResponseError('check_in_distance_minus_score', 'adjustScoreModal')"
                                            @blur="$v.adjustScoreModal.check_in_distance_minus_score.$touch"
                                        />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="getI18n(i18nCommon, 'radius')"
                                    label-for="check-in-distance-radius"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_score')">
                                    <b-input-group class="input-group-pdv-blue">
                                        <template v-slot:append>
                                            <b-input-group-text :title="i18nMeters">
                                                m
                                            </b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="check-in-distance-radius"
                                            v-model.number="$v.adjustScoreModal.check_in_distance_radius.$model"
                                            class="input-pdv-blue"
                                            type="number"
                                            min="0"
                                            max="1000"
                                            autocomplete="off"
                                            :disabled="loading || submitLoading"
                                            :state="validateField('check_in_distance_radius', 'adjustScoreModal')"
                                            @input="clearResponseError('check_in_distance_radius', 'adjustScoreModal')"
                                            @blur="$v.adjustScoreModal.check_in_distance_radius.$touch"
                                        />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col md="6">
                        <topic-header
                            :caption="i18nCheckOutDistance"
                            icon="fe-log-out"
                        />
                        <b-row>
                            <b-col md="4">
                                <b-form-group
                                    :label="getI18n(i18nKey, 'PERFORMANCE.points_if_within_the_radius')"
                                    label-for="check-out-distance-plus-score"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_score')">
                                    <b-input-group class="input-group-pdv-blue">
                                        <template v-slot:prepend>
                                            <b-input-group-text>
                                                <i class="fe-plus" />
                                            </b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="check-out-distance-plus-score"
                                            v-model.number="$v.adjustScoreModal.check_out_distance_plus_score.$model"
                                            class="input-pdv-blue"
                                            type="number"
                                            min="0"
                                            max="100"
                                            autocomplete="off"
                                            :disabled="loading || submitLoading"
                                            :state="validateField('check_out_distance_plus_score', 'adjustScoreModal')"
                                            @input="clearResponseError('check_out_distance_plus_score', 'adjustScoreModal')"
                                            @blur="$v.adjustScoreModal.check_out_distance_plus_score.$touch"
                                        />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="getI18n(i18nKey, 'PERFORMANCE.points_if_out_of_radius')"
                                    label-for="check-out-distance-minus-score"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_score')">
                                    <b-input-group class="input-group-pdv-blue">
                                        <template v-slot:prepend>
                                            <b-input-group-text>
                                                <i class="fe-minus" />
                                            </b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="check-out-distance-minus-score"
                                            v-model.number="$v.adjustScoreModal.check_out_distance_minus_score.$model"
                                            class="input-pdv-blue"
                                            type="number"
                                            min="0"
                                            max="100"
                                            autocomplete="off"
                                            :disabled="loading || submitLoading"
                                            :state="validateField('check_out_distance_minus_score', 'adjustScoreModal')"
                                            @input="clearResponseError('check_out_distance_minus_score', 'adjustScoreModal')"
                                            @blur="$v.adjustScoreModal.check_out_distance_minus_score.$touch"
                                        />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="getI18n(i18nCommon, 'radius')"
                                    label-for="check-out-distance-radius"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_score')">
                                    <b-input-group class="input-group-pdv-blue">
                                        <template v-slot:append>
                                            <b-input-group-text :title="i18nMeters">
                                                m
                                            </b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="check-out-distance-radius"
                                            v-model.number="$v.adjustScoreModal.check_out_distance_radius.$model"
                                            class="input-pdv-blue"
                                            type="number"
                                            min="0"
                                            max="1000"
                                            autocomplete="off"
                                            :disabled="loading || submitLoading"
                                            :state="validateField('check_out_distance_radius', 'adjustScoreModal')"
                                            @input="clearResponseError('check_out_distance_radius', 'adjustScoreModal')"
                                            @blur="$v.adjustScoreModal.check_out_distance_radius.$touch"
                                        />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6">
                        <topic-header
                            :caption="i18nBattery"
                            icon="fe-battery"
                        />
                        <b-row>
                            <b-col md="6">
                                <b-form-group
                                    :label="getI18n(i18nKey, 'PERFORMANCE.points_if_you_start_the_day_with_more')"
                                    label-for="battery-plus-score"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_score')">
                                    <b-input-group class="input-group-pdv-blue">
                                        <template v-slot:prepend>
                                            <b-input-group-text>
                                                <i class="fe-plus" />
                                            </b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="battery-plus-score"
                                            v-model.number="$v.adjustScoreModal.battery_plus_score.$model"
                                            class="input-pdv-blue"
                                            type="number"
                                            min="0"
                                            max="100"
                                            autocomplete="off"
                                            :disabled="loading || submitLoading"
                                            :state="validateField('battery_plus_score', 'adjustScoreModal')"
                                            @input="clearResponseError('battery_plus_score', 'adjustScoreModal')"
                                            @blur="$v.adjustScoreModal.battery_plus_score.$touch"
                                        />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group
                                    :label="getI18n(i18nCommon, 'minimum')"
                                    label-for="battery-mininum"
                                    class="required label-pdv"
                                    :invalid-feedback="getI18n('ERROR_CODES.invalid_score')">
                                    <b-input-group class="input-group-pdv-blue">
                                        <template v-slot:append>
                                            <b-input-group-text>
                                                <i class="fe-percent" />
                                            </b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="battery-mininum"
                                            v-model.number="$v.adjustScoreModal.battery_mininum.$model"
                                            class="input-pdv-blue"
                                            type="number"
                                            min="0"
                                            max="100"
                                            autocomplete="off"
                                            :disabled="loading || submitLoading"
                                            :state="validateField('battery_mininum', 'adjustScoreModal')"
                                            @input="clearResponseError('battery_mininum', 'adjustScoreModal')"
                                            @blur="$v.adjustScoreModal.battery_mininum.$touch"
                                        />
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </form>
        </template>
        <template v-slot:footer>
            <cancel-button
                ref="cancelButton"
                child-class="col-sm-4 float-left"
                :disabled="loading || submitLoading"
                @onClick="handleCancel"
            />
            <save-button
                ref="okButton"
                child-class="col-sm-4 float-right ml-3"
                :busy="submitLoading"
                :disabled="loading"
                @onClick="handleOk"
            />
        </template>
    </form-modal>
</template>

<script>
import UserPerformanceConfig from '@src/services/UserPerformanceConfig.js'

import {
    required,
    minValue,
    maxValue,
    integer,
} from 'vuelidate/lib/validators'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import FormModal from '@/src/components/FormModal.vue'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import CancelButton from '@/src/components/CancelButton'
import SaveButton from '@/src/components/SaveButton'
import TopicHeader from '@src/components/TopicHeader.vue'

const i18nKey = 'DASHBOARDS'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        CancelButton,
        SaveButton,
        ConfirmationFormModal,
        TopicHeader,
    },
    mixins: [
        validationMixin,
        swalFeedback,
    ],
    data() {
        return {
            i18nKey,
            i18nCommon,
            modal: {},
            loading: true,
            submitLoading: false,
            adjustScoreModal: {
                punctuality_plus_score: 0,
                punctuality_minus_score: 0,
                workschedule_plus_score: 0,
                workschedule_minus_score: 0,
                workschedule_mininum: 0,
                questionnaire_plus_score: 0,
                time_in_store_plus_score: 0,
                time_in_store_minus_score: 0,
                time_in_store_minutes: 0,
                check_in_distance_plus_score: 0,
                check_in_distance_minus_score: 0,
                check_in_distance_radius: 0,
                check_out_distance_plus_score: 0,
                check_out_distance_minus_score: 0,
                check_out_distance_radius: 0,
                battery_plus_score: 0,
                battery_mininum: 0,
            }
        }
    },
    validations: {
        adjustScoreModal: {
            punctuality_plus_score: {
                required,
                integer,
                minValue: minValue(0),
                maxValue: maxValue(100),
            },
            punctuality_minus_score: {
                required,
                integer,
                minValue: minValue(0),
                maxValue: maxValue(100),
            },
            workschedule_plus_score: {
                required,
                integer,
                minValue: minValue(0),
                maxValue: maxValue(100),
            },
            workschedule_minus_score: {
                required,
                integer,
                minValue: minValue(0),
                maxValue: maxValue(100),
            },
            workschedule_mininum: {
                required,
                integer,
                minValue: minValue(0),
                maxValue: maxValue(100),
            },
            questionnaire_plus_score: {
                required,
                integer,
                minValue: minValue(0),
                maxValue: maxValue(100),
            },
            time_in_store_plus_score: {
                required,
                integer,
                minValue: minValue(0),
                maxValue: maxValue(100),
            },
            time_in_store_minus_score: {
                required,
                integer,
                minValue: minValue(0),
                maxValue: maxValue(100),
            },
            time_in_store_minutes: {
                required,
                integer,
                minValue: minValue(0),
                maxValue: maxValue(120),
            },
            check_in_distance_plus_score: {
                required,
                integer,
                minValue: minValue(0),
                maxValue: maxValue(100),
            },
            check_in_distance_minus_score: {
                required,
                integer,
                minValue: minValue(0),
                maxValue: maxValue(100),
            },
            check_in_distance_radius: {
                required,
                integer,
                minValue: minValue(0),
                maxValue: maxValue(1000),
            },
            check_out_distance_plus_score: {
                required,
                integer,
                minValue: minValue(0),
                maxValue: maxValue(100),
            },
            check_out_distance_minus_score: {
                required,
                integer,
                minValue: minValue(0),
                maxValue: maxValue(100),
            },
            check_out_distance_radius: {
                required,
                integer,
                minValue: minValue(0),
                maxValue: maxValue(1000),
            },
            battery_plus_score: {
                required,
                integer,
                minValue: minValue(0),
                maxValue: maxValue(100),
            },
            battery_mininum: {
                required,
                integer,
                minValue: minValue(0),
                maxValue: maxValue(100),
            },
        },
    },
    computed: {
        i18nAdjustScore() {
            return this.getI18n(i18nCommon, 'adjust_score')
        },
        i18nPunctuality() {
            return this.getI18n(i18nCommon, 'punctuality')
        },
        i18nWorkSchedule() {
            return this.getI18n('WORKSCHEDULES', 'TITLES.work_schedule')
        },
        i18nQuestionnaire() {
            return this.getI18n('QUESTIONNAIRES', 'TITLES.questionnaire')
        },
        i18nTimeInStore() {
            return this.getI18n('POS_CHECK', 'FIELDS.time_in_store')
        },
        i18nCheckInDistance() {
            return this.getI18n(i18nCommon, 'checkin_distance')
        },
        i18nCheckOutDistance() {
            return this.getI18n(i18nCommon, 'checkout_distance')
        },
        i18nBattery() {
            return this.getI18n('POS_CHECK', 'FIELDS.battery')
        },
        i18nMinutes() {
            return this.getI18nModified({
                prefix: i18nCommon,
                suffix: 'minutes',
                modifier: 2,
            }).toLowerCase()
        },
        i18nMeters() {
            return this.getI18nModified({
                prefix: i18nCommon,
                suffix: 'meter',
                modifier: 2,
            }).toLowerCase()
        },
    },
    methods: {
        clearAllResponseError() {
            this.modalResponseErrors = {}
        },
        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },
        showModal() {
            this.submitLoading = false
            this.loadInformation()
            this.$refs.formModal.show()
            this.resetValidation()
        },
        handleCancel() {
            this.$refs.confirmationFormModal.showModal(true, this.handleClose)
        },
        handleClose(close) {
            if (!close) {
                return
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },
        handleOk() {
            this.$v.adjustScoreModal.$touch()
            if (!this.$v.adjustScoreModal.$invalid && !this.submitLoading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },
        async handleSubmit(submit) {
            if (!submit) {
                return
            }

            this.submitLoading = true

            const response = await UserPerformanceConfig.create(this.adjustScoreModal)
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nAdjustScore, 'edited')
            }

            if (statusCode === '417') {
                this.operationUnsuccessful(i18nKey, `ERRORS.${response.data.message}`, 'edited')
            }

            if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            }
            this.submitLoading = false
        },
        loadInformation() {
            this.loading = true

            UserPerformanceConfig.fetchAll()
                .then((response) => {
                    this.loading = false
                    if (response.data.data) {
                        this.adjustScoreModal = response.data.data
                    } else {
                        this.adjustScoreModal = {
                            punctuality_plus_score: 10,
                            punctuality_minus_score: 1,
                            workschedule_plus_score: 10,
                            workschedule_minus_score: 0,
                            workschedule_mininum: 50,
                            questionnaire_plus_score: 10,
                            time_in_store_plus_score: 10,
                            time_in_store_minus_score: 1,
                            time_in_store_minutes: 30,
                            check_in_distance_plus_score: 10,
                            check_in_distance_minus_score: 1,
                            check_in_distance_radius: 100,
                            check_out_distance_plus_score: 10,
                            check_out_distance_minus_score: 1,
                            check_out_distance_radius: 100,
                            battery_plus_score: 10,
                            battery_mininum: 90,
                        }
                    }
                })
                .catch((error) => {
                    this.loading = false
                    this.errorFeedback(error)
                })
        }
    },
}
</script>
