<template>
    <form-modal
        ref="formModal"
        :title="getI18nModified({ prefix: 'COMMON.filter', modifier: 2 })"
        @handleHide="handleHide">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <b-col md="12">
                    <b-form-group
                        :label="i18nPeriod"
                        class="required label-pdv"
                        label-for="filter-date"
                    >
                        <date-range-picker
                            :value.sync="filterModal.date"
                            :max-date="new Date()"
                            :disable-clean-button="true"
                        />
                    </b-form-group>
                    <b-form-group
                        :label="i18nTeam"
                        label-for="filter-team"
                        class="label-pdv">
                        <multiSelectWithService
                            :id="'filter-team'"
                            ref="teamMultiselect"
                            v-model="filterModal.teams"
                            :service="'teams'"
                            :searchable="true"
                            :multiple="true"
                            :parameters="parameters"
                        />
                    </b-form-group>
                    <b-form-group
                        :label="i18nUser"
                        label-for="filter-user"
                        class="label-pdv">
                        <multiSelectWithService
                            :id="'filter-user'"
                            ref="userMultiselect"
                            v-model="filterModal.users"
                            :service="'users'"
                            :searchable="true"
                            :multiple="true"
                            :parameters="usersParameters"
                        />
                    </b-form-group>
                </b-col>
            </form>
        </template>
        <template v-slot:footer>
            <save-button
                ref="okButton"
                :busy="submitLoading"
                :disabled="submitLoading"
                :title="getI18n('ACTION.filter')"
                @onClick="handleOk"
            />
            <clear-filter-button
                @onClick="handleClearFilter"
            />
        </template>
    </form-modal>
</template>

<script>
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import FormModal from '@/src/components/FormModal.vue'
import SaveButton from '@/src/components/SaveButton'
import ClearFilterButton from '@/src/components/ClearFilterButton'
import cloneObject from '@utils/clone-object'
import DateRangePicker from '@/src/components/DateRangePicker'
import multiSelectWithService from '@src/components/multiSelectWithService.vue'
import moment from 'moment';

const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        SaveButton,
        ClearFilterButton,
        DateRangePicker,
        multiSelectWithService,
    },
    mixins: [
        validationMixin,
        swalFeedback,
    ],
    props: {
        handleSubmit: {
            type: Function,
            required: true,
            default: function () {},
        },
    },
    data() {
        return {
            i18nCommon,
            filterModal: {},
            submitLoading: false,
            parameters: {
                with_admin: true,
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
            usersParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
                active: true,
            },
        }
    },
    validations: {},
    computed: {
        i18nPeriod() {
            return this.getI18n(i18nCommon, 'period')
        },
        i18nUser() {
            return this.getI18n('USERS', 'TITLES.user')
        },
        i18nTeam() {
            return this.getI18n('TEAMS', 'TITLES.team')
        },
        i18nSituation() {
            return this.getI18n(i18nCommon, 'status')
        },
    },
    methods: {
        showModal(previousFilters) {
            this.filterModal = cloneObject(previousFilters);
            this.submitLoading = false
            this.$refs.formModal.show()
        },
        cleanModal() {
            this.filterModal = Object.assign(this.filterModal, {
                date: {
                    start: moment().startOf('day').add(-30, 'days').toDate(),
                    end: moment().startOf('day').toDate()
                },
                teams: [],
                users: [],
                // active: {
                //     value: true,
                //     text: this.getI18n(i18nCommon, 'active')
                // },
            })
        },
        handleHide() {
            if (!this.submitLoading) {
                this.cleanModal();
            }
        },
        handleOk() {
            if (this.submitLoading) {
                return;
            }

            this.submitLoading = true;
            this.$props.handleSubmit(this.filterModal);
            this.$refs.formModal.hide()
        },
        async handleClearFilter() {
            this.cleanModal()
        }
    },
}
</script>
