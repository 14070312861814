<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3">
                <div class="col-sm-8">
                    <b-row>
                        <b-col md="5">
                            <b-form-group
                                :label="i18nPeriod"
                                class="required label-pdv"
                                label-for="filter-date"
                            >
                                <date-range-picker
                                    :value.sync="filters.date"
                                    :max-date="new Date()"
                                    :disabled="loading"
                                    :disable-clean-button="true"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group
                                :label="i18nTeam"
                                class="label-pdv"
                                label-for="filter-team"
                            >
                                <multiSelectWithService
                                    :id="'filter-team'"
                                    ref="teamMultiselect"
                                    v-model="filters.teams"
                                    :service="'teams'"
                                    :searchable="true"
                                    :multiple="true"
                                    :parameters="filterParameters"
                                    :disabled="loading"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div class="col-sm-4 align-self-center">
                    <div class="d-flex justify-content-between">
                        <more-filters-label-button class="col-sm-6" :disabled="loading" @onClick="showFilterModal" />
                        <filter-button class="col-sm-6 col-md-6" :disabled="loading" @onClick="filter" />
                    </div>
                </div>
            </div>
            <b-row class="mb-2">
                <div class="col-sm-12">
                    <div class="text-sm-right">
                        <adjust-score-label-button @onClick="showAdjustScoreModal" @refresh="refresh"  />
                    </div>
                </div>
            </b-row>
        </div>
        <div>
            <b-row>
                <b-col md="12">
                    <user-performance-resume ref="userPerformanceResume" />
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12">
                    <user-performance-chart ref="userPerformanceChart" />
                </b-col>
            </b-row>
            <b-row>
                <b-col class="text-sm-right" md="12">
                    <export-button
                        v-if="$can('CREATE', 'EXPORTJOB')"
                        type="user_performance"
                        :create-parameters="createAdditionalParameters"
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12">
                    <div class="card card-pdv">
                        <PageHeader :title="i18nPerformance" />
                        <div class="card-body">
                            <api-mode-vuetable
                                ref="vuetable"
                                :fetch-data="fetch"
                                :fields="fields"
                                :per-page="perPage"
                                :display-selection-records-per-page="true"
                                :additional-parameters="additionalParameters"
                                :sort-order="sortOrder">
                                <template slot="perc_total_point_user" slot-scope="props">
                                    <b-badge
                                        pill
                                        :style="`background-color: ${performancePercentageToColor(props.rowData.perc_total_point_user)};`"
                                    >
                                        {{ formatPercentageInteger(props.rowData.perc_total_point_user) }}
                                    </b-badge>
                                </template>
                                <template slot="user_grade" slot-scope="props">
                                    <label
                                        :style="`color: ${performanceNoteToColor(props.rowData.user_grade)};`"
                                    >
                                        {{ props.rowData.user_grade }}
                                    </label>
                                </template>
                            </api-mode-vuetable>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
        <user-performance-filter-modal ref="userPerformanceFilterModal" :handle-submit="filter" />
        <user-adjust-score-modal ref="userAdjustScoreModal" :handle-submit="filter" />
    </Layout>
</template>

<script>
import UserPerformanceService from '@src/services/dashboards/UserPerformanceService.js'

import ApiModeVuetable from '@src/components/ApiModeVuetable'
import Layout from '@layouts/main'
import PageHeader from '@/src/components/PageHeader.vue'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import DateRangePicker from '@/src/components/DateRangePicker'
import multiSelectWithService from '@src/components/multiSelectWithService'
import MoreFiltersLabelButton from '@/src/components/MoreFiltersLabelButton'
import AdjustScoreLabelButton from '@/src/components/AdjustScoreLabelButton'
import ExportButton from '@/src/components/ExportButton.vue'
import FilterButton from '@/src/components/FilterButton'
import userPerformanceChart from '@src/router/views/dashboards/performance/user/userPerformanceChart.vue'
import userPerformanceFilterModal from '@/src/router/views/dashboards/performance/user/userPerformanceFilterModal.vue'
import userPerformanceResume from '@src/router/views/dashboards/performance/user/userPerformanceResume.vue'
import userAdjustScoreModal from '@src/router/views/dashboards/performance/user/userAdjustScoreModal.vue'
import filterVuetable from '@src/mixins/filterVuetable'
import vuetableFormatter from '@src/mixins/vuetableFormatters'
import performance from '@src/mixins/performance'
import moment from 'moment';

const i18nKey = 'DASHBOARDS'
const i18nCommon = 'COMMON'

export default {
    components: {
        ApiModeVuetable,
        Layout,
        PageHeader,
        Breadcrumb,
        DateRangePicker,
        multiSelectWithService,
        MoreFiltersLabelButton,
        AdjustScoreLabelButton,
        ExportButton,
        FilterButton,
        userPerformanceChart,
        userPerformanceFilterModal,
        userPerformanceResume,
        userAdjustScoreModal,
    },
    mixins: [
        filterVuetable,
        vuetableFormatter,
        performance,
    ],
    data() {
        return {
            i18nKey,
            i18nCommon,
            loading: false,
            perPage: 10,
            additionalParameters: this.createAdditionalParameters(),
            filterParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
            filters: {
                date: {
                    start: moment().startOf('day').add(-30, 'days').toDate(),
                    end: moment().startOf('day').toDate()
                },
                teams: [],
                users: [],
                // active: {
                //     value: true,
                //     text: this.getI18n(i18nCommon, 'active')
                // },
            },
            sortOrder: [
                {
                    field: 'perc_total_point_user',
                    direction: 'desc',
                },
            ],
            fields: [
                {
                    name: 'user_name',
                    title: this.getI18n(i18nCommon, 'name'),
                    sortField: 'user_name',
                },
                {
                    name: 'team_name',
                    title: this.getI18n('TEAMS', 'TITLES.team'),
                    sortField: 'team_name',
                },
                {
                    name: 'perc_punctuality_point',
                    title: this.getI18n(i18nCommon, 'punctuality'),
                    formatter: this.formatPercentageInteger,
                    sortField: 'perc_punctuality_point',
                },
                {
                    name: 'perc_work_schedule_point',
                    title: this.getI18n('WORKSCHEDULES', 'TITLES.work_schedule'),
                    formatter: this.formatPercentageInteger,
                    sortField: 'perc_work_schedule_point',
                },
                {
                    name: 'perc_task_point',
                    title: this.getI18n('QUESTIONNAIRES', 'TITLES.questionnaire'),
                    formatter: this.formatPercentageInteger,
                    sortField: 'perc_task_point',
                },
                {
                    name: 'perc_time_in_store_point',
                    title: this.getI18n('POS_CHECK', 'FIELDS.time_in_store'),
                    formatter: this.formatPercentageInteger,
                    sortField: 'perc_time_in_store_point',
                },
                {
                    name: 'perc_checkin_distance_point',
                    title: this.getI18n(i18nCommon, 'checkin_distance'),
                    formatter: this.formatPercentageInteger,
                    sortField: 'perc_checkin_distance_point',
                },
                {
                    name: 'perc_checkout_distance_point',
                    title: this.getI18n(i18nCommon, 'checkout_distance'),
                    formatter: this.formatPercentageInteger,
                    sortField: 'perc_checkout_distance_point',
                },
                {
                    name: 'perc_battery_point',
                    title: this.getI18n('POS_CHECK', 'FIELDS.battery'),
                    formatter: this.formatPercentageInteger,
                    sortField: 'perc_battery_point',
                },
                {
                    name: 'total_point_user',
                    title: this.getI18n(i18nCommon, 'punctuation'),
                    sortField: 'total_point_user',
                },
                {
                    name: 'perc_total_point_user',
                    title: this.getI18n(i18nCommon, 'effectiveness'),
                    sortField: 'perc_total_point_user',
                },
                {
                    name: 'user_grade',
                    title: this.getI18n(i18nCommon, 'note'),
                    sortField: 'user_grade',
                },
            ],
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.dashboards',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.i18nUserPerformance,
                    active: true,
                },
            ]
        },
        i18nUserPerformance() {
            return this.getI18n(i18nKey, 'TITLES.performance_user')
        },
        i18nPeriod() {
            return this.getI18n(i18nCommon, 'period')
        },
        i18nTeam() {
            return this.getI18n('TEAMS', 'TITLES.team')
        },
        i18nPerformance() {
            return this.getI18n(i18nKey, 'TITLES.performance')
        },
    },
    mounted() {
        this.filter()
    },
    methods: {
        createAdditionalParameters() {
            if (!this.filters) {
                this.filters = {
                    date: {
                        start: moment().startOf('day').add(-30, 'days').toDate(),
                        end: moment().startOf('day').toDate()
                    },
                    teams: [],
                    users: [],
                    // active: {
                    //     value: true,
                    //     text: this.getI18n(i18nCommon, 'active')
                    // },
                }
            }

            // const search = this.createSearch({
            //     active: this.filters.active ? String(this.filters.active.value) : null,
            // })

            const period = this.formatFilterDate(this.filters.date)
            return this.formatParameters({
                // search,
                team_id: this.filters.teams.map((team) => team.id),
                user_id: this.filters.users.map((user) => user.id),
                start: period.from,
                end: period.to,
            })
        },
        filter(appliedFilters) {
            if (appliedFilters) {
                this.filters = appliedFilters
            }
            this.additionalParameters = this.createAdditionalParameters()
            this.refresh()
        },
        showFilterModal() {
            this.$nextTick(() => this.$refs.userPerformanceFilterModal.showModal(this.filters))
        },
        showAdjustScoreModal() {
            this.$nextTick(() => this.$refs.userAdjustScoreModal.showModal())
        },
        fetch(url, params) {
            return UserPerformanceService.fetchVuetable(url, params)
        },
        refresh() {
            this.loading = true

            this.$refs.userPerformanceResume.refresh(this.additionalParameters)
            this.$refs.userPerformanceChart.refresh(this.additionalParameters)
            this.refreshTable()

            this.loading = false
        },
        refreshTable() {
            this.$nextTick(() => this.$refs.vuetable.refresh())
        },
    },
}
</script>
